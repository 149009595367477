import routes from '@config/routes';
import instance from '@instance';

export const checkUserExisting = async (values) => {
  const body = JSON.stringify(values);

  const response = await instance.server(routes.userExist, {
    body,
  });

  return response.json();
};
