'use client';

import useHitCounter from '@hooks/use-hit-counter';
import {
  postAffiliateReferral,
  postAffiliateVisit,
} from '@requests/get-pages-content/affiliates';
import { isToday } from '@utils/dates';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

export const AFFILIATE_VISIT_COOKIE = 'affiliate_visit';
export const AFFILIATE_REFERRAL_COOKIE = 'affiliate_referral';

const TrackingClient = ({ user }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const { addVisit } = useHitCounter(user);

  useEffect(() => {
    const createReferral = async (affiliationId) => {
      await postAffiliateReferral(affiliationId, user?.token?.accessToken);
    };

    const addAffiliateSiteVisit = async (url, affiliationId) => {
      const isLoggedUser = !!user;

      await postAffiliateVisit(url, affiliationId, isLoggedUser);
    };

    const affiliationId = searchParams?.get('af');
    if (affiliationId) {
      const wasVisited = hasVisitedUrl(pathname, affiliationId);

      if (!wasVisited) {
        addVisitedUrl(pathname, affiliationId);
        addAffiliateSiteVisit(pathname, affiliationId);
      }

      if (!user) {
        setCookie(AFFILIATE_REFERRAL_COOKIE, affiliationId);
      }
    }

    const affiliationCookie = getCookie(AFFILIATE_REFERRAL_COOKIE);

    if (user && (affiliationId || affiliationCookie)) {
      createReferral(affiliationId || affiliationCookie);
      deleteCookie(AFFILIATE_REFERRAL_COOKIE);
    }
  }, [pathname]);

  useEffect(() => {
    addVisit();
  }, [addVisit]);

  const hasVisitedUrl = (url, affiliationId) => {
    const affiliateVisits = JSON.parse(
      (getCookie(AFFILIATE_VISIT_COOKIE) as string) || '[]'
    );

    const visit = affiliateVisits.find(
      (visit) => visit.url === url && visit.affiliationId === affiliationId
    );

    if (!visit) {
      return false;
    }

    if (!isToday(visit.time)) {
      const filteredVisits = affiliateVisits.filter(
        (visit) => visit.url !== url && visit.affiliationId !== affiliationId
      );
      setCookie(AFFILIATE_VISIT_COOKIE, JSON.stringify(filteredVisits));

      return false;
    }

    return true;
  };

  const addVisitedUrl = (url, affiliationId) => {
    const affiliateVisits = JSON.parse(
      (getCookie(AFFILIATE_VISIT_COOKIE) as string) || '[]'
    );

    affiliateVisits.push({ url, affiliationId, time: Date.now() });

    setCookie(AFFILIATE_VISIT_COOKIE, JSON.stringify(affiliateVisits));
  };

  return <></>;
};

export default TrackingClient;
