import { useSearchParams } from 'next/navigation';

type UserType = 'user' | 'agency';

export function useAuthUserType() {
  const searchParams = useSearchParams();

  const userType = (searchParams?.get('userType') as UserType) || 'user';

  return userType;
}
