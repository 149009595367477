import create from 'zustand';

// Define the types for the state
interface State {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  repeatPassword: string;
}

// Define the types for the methods
interface Actions {
  clearState: () => void;
  clearSpecificState: (fields: (keyof State)[]) => void;
  addState: (newState: Partial<State>) => void;
}

// Combine the state and actions types
type Store = State & Actions;

// Create the Zustand store with TypeScript support
export const useCompleteAccountStore = create<Store>((set) => ({
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  repeatPassword: '',

  clearState: () =>
    set({
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      repeatPassword: '',
    }),

  clearSpecificState: (fields) =>
    set((state) => {
      const newState = { ...state };
      fields.forEach((field) => {
        if (newState[field] !== undefined) {
          newState[field] = '';
        }
      });
      return newState;
    }),

  addState: (newState) =>
    set((state) => ({
      ...state,
      ...newState,
    })),
}));
