import { serverFetcher } from '@instance';
import routes from '@config/routes';

export const getOrdersReport = async (token) => {
  try {
    return await serverFetcher(routes.ordersReport, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    return [];
  }
};

export const getUsersReport = async (token) => {
  try {
    return await serverFetcher(routes.usersReport, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    return [];
  }
};

export const postVisit = async (isAuthenticated) => {
  try {
    return await serverFetcher(routes.postVisit, {
      method: 'POST',
      body: {
        isAuthenticated,
      },
    });
  } catch (err) {
    return [];
  }
};
