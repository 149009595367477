import { postVisit } from '@requests/get-pages-content/reports';
import { isToday } from '@utils/dates';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';

const ADDED_VISIT_COOKIE = 'added_visit';

const useHitCounter = (user) => {
  const addVisit = async () => {
    const cookieDate = getCookie(ADDED_VISIT_COOKIE);
    if (!cookieDate || !isToday(cookieDate)) {
      setCookie(ADDED_VISIT_COOKIE, Date.now());
      await postVisit(!!user);
    }
  };

  const resetVisit = () => {
    deleteCookie(ADDED_VISIT_COOKIE);
  };

  return {
    addVisit,
    resetVisit,
  };
};

export default useHitCounter;
