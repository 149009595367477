export const AUTH_PROVIDERS = {
  credentials: 'credentials',
  google: 'google',
  est: 'EST',
};

export const AUTH_PROVIDERS_URLS = {
  google: {
    path: `${process.env.SERVER_URL}/auth/google`,
    text: 'with Google',
  },
  // facebook: {
  //   path: `${process.env.SERVER_URL}/auth/facebook`, text: 'with Facebook',
  // },
};
