import moment from 'moment-timezone';
import * as yup from 'yup';

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
const passwordHint =
  'Must contain at least one uppercase letter, one lowercase letter, one number and one special character.';

export const signupValidationSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup.string().email().required('Required'),
  accept: yup
    .boolean()
    .oneOf(
      [true],
      'You must read, agree with, and accept all of the terms and conditions.'
    )
    .required(),
  password: yup
    .string()
    .min(8)
    .matches(passwordRegex, passwordHint)
    .required('Required'),
  repeatPassword: yup
    .string()
    .min(6)
    .required('Required')
    .oneOf([yup.ref('password'), ''], 'Passwords do not match.')
    .label('repeat password'),
});

export const affiliateSignupValidationSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup.string().email().required('Required'),
  accept: yup
    .boolean()
    .oneOf(
      [true],
      'You must read, agree with, and accept all of the terms and conditions.'
    )
    .required(),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters long.')
    .matches(passwordRegex, passwordHint)
    .required('Required'),
  repeatPassword: yup
    .string()
    .min(8, 'Password must be at least 8 characters long.')
    .required('Required')
    .oneOf([yup.ref('password'), ''], 'Passwords do not match.')
    .label('repeat password'),
  businessName: yup.string().required('Required'),
  country: yup.string().required('Required'),
  city: yup.string().required('Required'),
  streetAddress: yup.string().required('Required'),
  zipCode: yup.string().required('Required'),
  industry: yup.string().required('Required'),
});

export const emailValidationSchema = yup.object().shape({
  email: yup.string().email().required('Required'),
});
export const loginValidationSchema = yup.object().shape({
  email: yup.string().email().required('Required'),
  password: yup.string().min(8).required('Required'),
  userType: yup.string().optional(),
});

export const setNewPasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8)
    .matches(passwordRegex, passwordHint)
    .required('Required'),
  repeatPassword: yup
    .string()
    .min(6)
    .required('Required')
    .oneOf([yup.ref('password'), ''], 'Passwords do not match.')
    .label('repeat password'),
});

export const editProfileInfoValidationSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  phoneNumber: yup.string().matches(/^[0-9]{10}$/, 'must be a number'),
  dateOfBirth: yup.string(),
  // .required('Please input your age.')
  // .test(
  //   'DOB2',
  //   'Invalid date.',
  //   (date) => moment(date).year() < moment().year()
  // )
  // .test(
  //   'DOB1',
  //   'Must be 18 or older.',
  //   (date) => moment().diff(moment(date), 'years') >= 18
  // ),
});

export const editRoleValidationSchema = yup.object().shape({
  role: yup.string().required('Required'),
});
