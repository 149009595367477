// 'use server';
import routes from '@config/routes';
import instance from '@instance';
import { UserInterface } from '@interfaces/auth';
import {
  passwordWithResetSchema,
  sendCodeSchema,
  signupValidationSchema,
  validateCodeSchema,
  validateUserSchema,
} from './schema';
import { ValidateCodeResponse } from './types';

export const checkUserExisting = async (values: typeof validateUserSchema) => {
  const body = JSON.stringify(values);

  const response = await instance.server(routes.userExist, {
    body,
  });

  return response.json();
};
export type checkUserExistingResponse = Awaited<
  ReturnType<typeof checkUserExisting>
>;

export const sendVerificationCode = async (values: typeof sendCodeSchema) => {
  const body = JSON.stringify(values);

  const response = await instance.server(routes.sendVerificationCode, {
    body,
  });

  const contentType = response.headers.get('content-type');

  if (contentType && contentType.includes('application/json')) {
    return await response.json();
  } else {
    return await response.text();
  }
};

export const sendPasswordlessCode = async (values: typeof sendCodeSchema) => {
  const body = JSON.stringify(values);

  const response = await instance.server(routes.sendPasswordlessCode, {
    body,
  });

  console.log(`response: ${JSON.stringify(response)}`);

  return response.text();
};

export const passwordResetWithCode = async (
  values: typeof passwordWithResetSchema
) => {
  const body = JSON.stringify(values);

  try {
    const response = await instance.server(routes.passwordResetWithCode, {
      body,
    });

    if (response.status === 201) {
      return {
        message: 'Password reset successfully.',
      };
    }

    return response.json();
  } catch (error) {
    return {
      data: 'Invalid code.',
    };
  }
};

export const validateCode = async (
  values: typeof validateCodeSchema
): Promise<ValidateCodeResponse> => {
  const body = JSON.stringify(values);

  const response = await instance.server(routes.validatePasswordlessCode, {
    body,
  });

  return response.json();
};

export type ValidatePasswordlessCode = Awaited<
  ReturnType<typeof checkUserExisting>
>;

export const signup = async (
  values: typeof signupValidationSchema
): Promise<UserInterface> => {
  const { repeatPassword, ...input } = values;
  const body = JSON.stringify(input);

  const response = await instance.server('/auth/signup', {
    body,
  });

  return response.json();
};



export type SignupResponse = Awaited<ReturnType<typeof checkUserExisting>>;
