import * as Yup from 'yup';

export const validateUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  userType: Yup.string().optional(),
});

export const validateCodeSchema = Yup.object().shape({
  email: Yup.string().required(),
  code: Yup.string().required(),
  userType: Yup.string()
    .optional()
    .test(
      'valid-userType',
      'userType must be either "agency" or "user"',
      (value) => {
        // Check if the value is either "agency" or "user"
        return value === 'agency' || value === 'user';
      }
    ),
});

export const sendCodeSchema = Yup.object().shape({
  email: Yup.string(),
  userType: Yup.string()
    .optional()
    .optional()
    .test(
      'valid-userType',
      'userType must be either "agency" or "user"',
      (value) => {
        // Check if the value is either "agency" or "user"
        return value === 'agency' || value === 'user';
      }
    ),
});
export const passwordWithResetSchema = Yup.object().shape({
  email: Yup.string(),
  code: Yup.string(),
  password: Yup.string(),
  userType: Yup.string().optional(),
});
export const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d|[~#@$%&!*?^-]).*$/;

export const signupValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  affiliationId: Yup.string().optional(),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  password: Yup.string()
    .min(8)
    .matches(
      passwordRegex,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character and be at least 8 characters long'
    )
    .required('Required'),
  repeatPassword: Yup.string()
    .min(6)
    .required('Required')
    .oneOf([Yup.ref('password'), ''], 'Passwords do not match.')
    .label('repeat password'),
});
